import * as React from "react";

const VideoIcon = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 15V9a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2M18.375 8.3l-3 2.4a1 1 0 0 0-.375.78v1.04a1 1 0 0 0 .375.78l3 2.4A1 1 0 0 0 20 14.92V9.08a1 1 0 0 0-1.625-.78"
    ></path>
  </svg>
);

export default VideoIcon;
