import React, { useContext, useRef, useState } from "react";
import classes from "./WelcomeMessage.module.css";
import apple from "./../../../assets/images/apple.png";
import android from "./../../../assets/images/android.png";
import information from "./../../../assets/images/information_project.png";
import { useTranslation } from "react-i18next";
import {
  androidAppLink,
  informationLink,
  informationLinkEN,
  iosAppLink,
  welcomeMessageContent,
} from "../../../../SMARTCITY/hardCodeData/DATA";
import LanguageContext from "../../../hooks/language-context";
import { useHistory } from "react-router-dom";
import SvgTermsofuse2 from "../../../assets/icons/Termsofuse2";
import sponsor from "./../../../../SMARTCITY/assets/images/sponsor.png";
import VideoIcon from "../../../assets/icons/VideoIcon";
import ViewModal from "../../utils/ViewModal";
import "../../utils/ViewModal.css";
import PromoVideo from "../../../assets/promo_video.mp4";

const WelcomeMessage = () => {
  const { t } = useTranslation();
  const languageCtx = useContext(LanguageContext);
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const videoRef = useRef(null); // Reference to the video element

  const CloseModal = () => {
    if (videoRef.current) {
      videoRef.current.pause(); // Pause the video
      videoRef.current.currentTime = 0; // Optionally reset to start
    }
    setIsModalVisible(false);
  };

  return (
    <div className={classes.welcome_placeholder}>
      <p>{t(welcomeMessageContent[0])}</p>
      <p>{t(welcomeMessageContent[1])}</p>

      <div>
        <div className={classes.logoimgtxt}>
          <a href="https://www.pogoni.gr/index.php/smart-city" target="_blank">
            <img
              src={sponsor}
              alt="sponsor logo"
              className={classes.logo_normal}
            />
          </a>
        </div>
        <span className={classes.logotxt}>
          Με τη συγχρηματοδότηση της Ελλάδας και της Ευρωπαϊκής Ένωσης
        </span>
        <div
          style={{ marginBottom: 5 }}
          onClick={() => {
            console.log("CLicked");
            setIsModalVisible(true);
          }}
        >
          <VideoIcon width={35} height={30} />
          {languageCtx.languageCode === "el" ? (
            <p>{t("Βίντεο Παρουσίασης")}</p>
          ) : (
            <p>{t("Promo Video")}</p>
          )}
        </div>
        <div
          style={{ marginBottom: 5 }}
          onClick={() => history.push("/terms-of-use")}
        >
          <SvgTermsofuse2 width={35} />
          {languageCtx.languageCode === "el" ? (
            <p>{t("Δήλωση Προσβασιμότητας")}</p>
          ) : (
            <p>{t("Accessibility Statement")}</p>
          )}
        </div>
        <div
          style={{ marginBottom: 5 }}
          onClick={() =>
            window.open(
              languageCtx.languageCode === "en"
                ? informationLinkEN
                : informationLink
            )
          }
        >
          <img
            src={information}
            className={classes.inf_normal}
            alt={"information-application"}
            width={35}
          />
          <p>{t("Πληροφορίες για το έργο")}</p>
        </div>
        <div>
          {/* <img
            src={apple}
            alt={"apple-application"}
            width={150}
            onClick={() => window.open(iosAppLink)}
          />
          <img
            src={android}
            alt={"android-application"}
            width={150}
            onClick={() => window.open(androidAppLink)}
          /> */}
        </div>
      </div>
      <ViewModal
        modalStatus={isModalVisible}
        title={
          languageCtx.languageCode === "el" ? (
            <p>{t("Βίντεο Παρουσίασης")}</p>
          ) : (
            <p>{t("Promo Video")}</p>
          )
        }
        closeModal={CloseModal}
      >
        <video ref={videoRef} width="800" height="400" controls>
          <source src={PromoVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </ViewModal>
    </div>
  );
};

export default WelcomeMessage;
